import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Translator from "./translations";
import './assets/styles/style.css';
import Page from "./scenes/page";
import Home from "./scenes/home";
import Loader from "./components/loader";
import Error from "./components/error";

const root = ReactDOM.createRoot(document.getElementById('root'));
Translator.init();

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
        }
    }

    render() {
        const { error, loading } = this.state;

        return (
            <Loader visible={loading}>
                <Error visible={error}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home />}></Route>
                            <Route path="/privacy-policy" element={<Page slug="privacy-policy" />}></Route>
                            <Route path="/terms-of-service" element={<Page slug="terms-of-service" />}></Route>
                        </Routes>
                    </BrowserRouter>
                </Error>
            </Loader>
        );
    }
}

root.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>
);

serviceWorkerRegistration.register();
reportWebVitals();
