import React from 'react';
import './style.css';
import Header from "../../components/header";
import Footer from "../../components/footer";
import {useTranslation} from "react-i18next";

export default function Home(props = {}) {
    const { t } = useTranslation();

    return (
        <div className="page-wrapper">
            <Header />
            <div className="home">
                <h1>{ t('home.title') }</h1>
                <h4>{ t('home.subtitle') }</h4>
                <p>
                </p>
                <div className="links-wrapper">

                </div>
            </div>
            <Footer/>
        </div>
    );
}
