import Translator from "../translations";
import HttpClient from "./http_client";

const locale = Translator.getLanguage();
const api_url = `${process.env.REACT_APP_API_URL}/${locale}/mobile`;

const Api = {
    loadCmsPage(slug, successCallback, errorCallback) {
        const url = `${api_url}/cms/page/${slug}`;

        HttpClient.get(
            url,
            (data) => {
                successCallback(data && data.content ? data.content : null);
            },
            errorCallback
        );
    },
}

export default Api;
