// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "error": {
        "title": "An error occurred",
        "message": "The page is not responding due to an error.<br/>Try reloading it.",
        "refresh_button": "Refresh the page"
    },
    "home": {
        "title": "WedVite",
        "subtitle": "Wedding manager & gallery of wedding photos on your phone",
        "version": "Software version: {{ version }}",
        "links": {
            "privacy_policy": "Privacy Policy",
            "terms_of_service": "Terms of Service",
            "download_app": "Download the app"
        }
    },
    "page": {
        "back_home": "Back to homepage",
    }
}
