import React from 'react';
import logo from "../../assets/image/logo.svg";
import {Link} from "react-router-dom";

export default function Header(props = {}) {
    return (
        <div className="header">
            <Link to='/'>
                <img src={logo} alt="logo" className="logo"/>
            </Link>
        </div>
    )
}
