// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "error": {
        "title": "Si è verificato un errore",
        "message": "La pagina non risponde a causa di un errore.<br/>Prova a ricaricarla.",
        "refresh_button": "Aggiorna la pagina"
    },
    "home": {
        "title": "WedVite",
        "subtitle": "Gestore matrimoniale e galleria fotografica di matrimoni sul tuo telefono",
        "version": "Versione del software: {{ version }}",
        "links": {
            "privacy_policy": "Informativa sulla privacy",
            "terms_of_service": "Termini di servizio",
            "download_app": "Scarica l'app"
        }
    },
    "page": {
        "back_home": "Torna alla pagina iniziale",
    }
}
