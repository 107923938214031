import React, {Component} from 'react';
import './style.css';
import api from "../../services/api";
import Loader from "../../components/loader";
import Error from "../../components/error";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slug: props.slug,
            content: null,
            loading: true,
            error: false,
        };
    }

    componentDidMount() {
        const { slug } = this.props;

        this.loadCmsPage(slug);
    }

    loadCmsPage(slug) {
        api.loadCmsPage(
            slug,
            (data) => this.setState({
                content: data,
                loading: false,
                error: false
            }),
            () => this.setState({
                error: true,
                loading: false,
                content: null
            })
        );
    }

    render() {
        const { content, loading, error } = this.state;
        const { t } = this.props;

        return (
            <Loader visible={loading}>
                <Error visible={error}>
                    <div className="page-wrapper">
                        <Header />
                        <Link to={'/'} className="link back">
                            <i className="fa fa-angle-left" />
                            { t('page.back_home') }
                        </Link>
                        <div className="page">
                            <div dangerouslySetInnerHTML={{__html: content}}/>
                        </div>
                        <Footer />
                    </div>
                </Error>
            </Loader>
        )
    }
}

export default withTranslation()(Page);
