// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "error": {
        "title": "Wystąpił błąd",
        "message": "Strona nie odpowiada z powodu błędu.<br/>Spróbuj załadować ją ponownie.",
        "refresh_button": "Odśwież stronę"
    },
    "home": {
        "title": "WedVite",
        "subtitle": "Manager weselny & galeria ślubnych fotografii w Twoim telefonie",
        "version": "Wersja oprogramowania: {{ version }}",
        "links": {
            "privacy_policy": "Polityka prywatności",
            "terms_of_service": "Regulamin użytkownika",
            "download_app": "Pobierz aplikację"
        }
    },
    "page": {
        "back_home": "Powrót do strony głównej",
    }
}
