const HttpClient = {
    get(url, successCallback, errorCallback) {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }

        fetch(url, config)
            .then(res => {
                if (res.status !== 200 && res.status !== 404) {
                    return errorCallback("Server responds with error!");
                }

                return res.json();
            })
            .then(
                response => {
                    if (response && response.hasOwnProperty('data')) {
                        return successCallback(response.data);
                    }

                    successCallback(null);
                },
                err => errorCallback(err)
            )
        ;
    }
}

export default HttpClient;
