import React from 'react';
import './style.css';
import {useTranslation} from "react-i18next";

export default function Error(props = {}) {
    const { visible, children } = props;
    const { t } = useTranslation();
    const refresh = () => window.location.reload();

    return visible ? (
        <div className="wrapper">
            <i className="fa fa-heart-crack"></i>
            <span className="title">
                {t('error.title')}
            </span>
            <span className="message" dangerouslySetInnerHTML={{__html: t('error.message')}}/>
            <button type="button" onClick={refresh} className="btn-refresh">
                <i className="fa fa-arrows-rotate"></i>
                {t('error.refresh_button')}
            </button>
        </div>
    ) : children;
}
