import React from 'react';
import './style.css';

export default function Loader(props = {}) {
    const { visible, children } = props;

    return visible ? (
        <div className="wrapper">
            <i className="spinner fa fa-heart"></i>
        </div>
    ) : children;
}
