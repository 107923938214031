import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as resources from './locales';

const Translator = {
    getLocale() {
        return navigator.language;
    },

    getLanguage() {
        const locale = this.getLocale();
        let language = locale.split('-')[0];

        if (language) {
            language = language.split('_')[0];
        }

        if (!language) {
            return 'en';
        }

        return language;
    },

    init() {
        i18n.use(initReactI18next).init({
            compatibilityJSON: 'v3',
            resources: {
                ...Object.entries(resources).reduce(
                    (acc, [key, value]) => ({
                        ...acc,
                        [key]: {translation: value}
                    }),
                    {}
                )
            },
            lng: Translator.getLanguage(),
            fallbackLng: 'pl',
            interpolation: {
                escapeValue: false
            }
        });
    }
}

export default Translator;
